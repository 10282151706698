import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet";

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

import DetailsHeader from "../components/sections/details/detailsheader"
import Accordian from "../components/sections/details/accordian"

import FadeIn from 'react-fade-in';

import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query {
    allStrapiProductdetail {
      edges {
        node {
          detailHeader
          detailText
        }
      }
    }
  }
`;

const ProductDetails = () => (
  <Layout>
    <Helmet>
      <title>Dili – Quick & Easy Pay later Loans at No Cost EMI</title>
      <meta name="description" content="Dili converts your high-ticket purchases into affordable zero cost EMIs to accelerate your personal & professional growth." />
    </Helmet>
    <Navigation></Navigation>
    <FadeIn>
    <DetailsHeader></DetailsHeader>
    <DetailsContainer>
      <StaticQuery
        query={query}
        render={data => (
          <div>
            {data.allStrapiProductdetail.edges.map(productdetail => (
              <Accordian title={productdetail.node.detailHeader} content={productdetail.node.detailText}></Accordian>
            ))}
          </div>
        )}
      />
    </DetailsContainer>    
    </FadeIn>
    <Footer></Footer>
  </Layout>
)

export default ProductDetails

export const DetailsContainer = styled.div`
  margin-bottom: 80px;
`