import React, { useState, useRef } from "react";
import styled from "styled-components"
import { motion } from "framer-motion"

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <AccordianSection className="accordion__section">
      <AccordianButton className={`accordion ${setActive}`} onClick={toggleAccordion} whileHover={{ scale: 1.02 }}>
        <AccordianTitle className="accordion__title">{props.title}</AccordianTitle>
      </AccordianButton>
      <AccordianContent
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <AccordianText
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </AccordianContent>
    </AccordianSection>
  );
}

export default Accordion;

export const AccordianSection = styled.div`
    display: flex;
    flex-direction: column;
`
export const AccordianButton = styled(motion.button)`
    background-color: #E0F2FC;
    color: #142630;
    cursor: pointer;
    margin: 0 120px;
    padding: 32px 32px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 20px;
    transition: background-color 0.6s ease;
`
  
export const AccordianTitle = styled.p`
    font-size: 28px;
    text-align: left;
    line-height: 1.4;
`
  
export const AccordianContent = styled.div`
    background-color: white;
    color: #142630;
    overflow: auto;
    margin: 12px 120px;
    transition: max-height 0.6s ease;
`
  
export const AccordianText = styled.div`
    font-size: 24px;
    padding: 16px;
    margin-bottom: 24px;
    line-height: 1.5;
`